<template>
  <div style="padding: 0;margin: 0;">
    <!--  标题  -->
    <div class=" flex justify-start align-center bg-white" style="height: 80px;width: 100%;position: fixed;top: 0;z-index: 999">
      <div class="margin-left-xl"></div>
<!--      <img :src="require('@/assets/main_logo.png')" style="height: 60px">-->
      <div class="logo_font" style="font-size: 50px;color: #00a870">竟渡</div>
    </div>

    <div class="" style=";width: 100%;margin-top: 80px;">
      <!--  轮播图  -->
      <div class="" style="height: 600px;width: 100%">
        <!-- 轮播图 -->
        <div class="swiper">
          <el-carousel class="el-carousel" height="600px" :interval="4000" arrow="always">
            <el-carousel-item v-for="item in bannerPic" :key="item">
              <el-image :src="item.url" style="width: 100%; height: 100%" fit="cover"/>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="" style="width: 100%">
        <div class="padding-top-xl" style="position: relative">
          <div style="position: absolute;color: #eeeeee;font-size: 46px;top: 30px;left:46%">ABOUT</div>
          <div class="flex justify-center align-center" style="font-size: 34px; position:  absolute;left:45%;top:20px">关于我们</div>
        </div>
        <div class="flex justify-start align-center padding-lr-xl">
          <div class="padding-tb-xl" style="width: 40%;">
            <el-image :src="require('../assets/4.jpg')" style="width: 80%; height: 80%;border-top-right-radius: 40px;border-bottom-left-radius:40px;overflow: hidden"
                      fit="cover"/>
          </div>
          <div style="width: 60%;font-size: 24px">
            <div>山东竟渡旗下平台——寓健身，是全球领先的健身生活方式平台。自2020年成立至今，已与1500+家精品健身工作室合作带来卓越用户体验。</div>
            <div style="height: 30px"></div>
            <div>无论是健身先锋达人还是精致生活体验家，轻松登录寓健app，即可跨城市跨场馆按次约课，轻松探索千余家精品场馆、抢先打卡小众精品工作室。</div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-around align-center text-white bg-gradual-green" style="height: 100px;width: 100%;">
      <div>
        <div class="flex justify-start align-center">
          <el-icon size="20px">
            <Location/>
          </el-icon>
          公司地址
        </div>
        <div class="flex justify-start margin-tb-xs">山东省潍坊市寿光市圣城街道渤海路与公园街交叉路口东路南金顿大厦1号楼1412室</div>
<!--        <div class="flex justify-start">无锡市新吴区菱湖大道97号无锡国际生命科学创新园8层</div>-->
      </div>
      <div>
        <div class="flex justify-start align-center">联系方式</div>
        <div class="flex justify-start align-center margin-tb-xs">
          <div class="flex">
            <el-icon size="20px">
              <Cellphone/>
            </el-icon>
            <div class="margin-left-xs">15953683676</div>
          </div>
        </div>
        <div class="flex justify-start align-center">
          <el-icon size="20px">
            <Message/>
          </el-icon>
          <div class="margin-left-xs">sdsglyj@126.com</div>
        </div>
      </div>
    </div>
    <div class="flex justify-center align-center bg_main_color" style="height: 60px;width: 100%;">
      <div style="color: white">
        <div class="flex justify-center align-center">版权所有 @山东竟渡教育科技有限公司</div>
        <div class="flex justify-center align-center">鲁ICP备2023006292号-4</div>
      </div>
    </div>
  </div>
</template>

<script>

import {onMounted, reactive, ref} from "vue";

export default {
  name: "mainPage",
  setup() {
    const bannerPic = reactive([
      // {url: 'https://front-resource.chic-health.com/smoke_static/jieyandehaochu.jpg'},
      // {url: 'https://front-resource.chic-health.com/smoke_static/jieyanhouduojiunenghuifu.jpg'},
      // {url: 'https://front-resource.chic-health.com/smoke_static/jieyanhouweishenmechuxianjieduanzhengzhuang.jpg'}
      {
        url:require("../assets/1.jpg")
      },
      {
        url:require("../assets/2.jpg")
      },
      {
        url:require("../assets/3.jpg")
      },
    ]);


// 获取轮播图片的节点
    const bannerImg = ref()

// 轮播图自适应高度
    const newHeight = ref('315px')


// 监听屏幕缩放事件，

    onMounted(() => {
      // window.onresize = () => {
      //   newHeight.value = bannerImg.value[0].clientHeight + 'px'
      // }
    })
    return {
      bannerImg,
      bannerPic,
      newHeight
    }
  }
}
</script>

<style lang="scss">
.logo {
  width: 200px;
  height: 100%;
}

.bg_main_color {
  background-color: #00a870;
  //#85dbbe
  //  #00a870;
}

.swiper {
  margin: 0 0 40px 0;
  min-width: 1210px;
  overflow: hidden;


  .el-carousel {
    width: 100%;
    overflow: hidden;

    :deep(.el-carousel__container) {
      height: v-bind(newHeight);
    }

    :deep(.el-carousel__item) {
      height: auto !important;
    }
  }
}
.logo_font{
  //font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-family: "Baoli SC";
}
</style>