import { createRouter, createWebHashHistory } from 'vue-router'

import mainPage from '@/components/mainPage'
import notFoundPage from "@/components/notFoundPage";

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: mainPage,
  },
  {
    path: "/404",
    component: notFoundPage
  },
  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log('to-->');
//   console.log(to)
//   console.log('from-->');
//   console.log(from)
//   const token = localStorage.getItem('token')
//   console.log(token)
//   if (token){
//     // if (from.path === '/'){
//     //   let str = to.path;
//     //   const path ='/' + str.split('/')[1]
//     //   console.log('/')
//     //
//     //   console.log(path)
//     //   localStorage.setItem('currentRoute',path)
//     //   next()
//     // }else {
//     //   next()
//     // }
//     next()
//   }else {
//     if(to.fullPath === '/login'){
//       next()
//     }else {
//       next({
//         path:"/login",
//       });
//     }
//   }
// })

export default router
